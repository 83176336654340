import {
  PaymentWidgetInstance,
  loadPaymentWidget,
} from "@tosspayments/payment-widget-sdk";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Div from "~/components/atoms/div";
import { orderRequestState } from "~/store/order-state";
import { OrderInfoType, OrderRequestType } from "~/types/order-type";

interface PaymentProps {
  title: string;
  orderInfo: OrderInfoType;
  orderRequest: OrderRequestType;
  buttonRef: React.RefObject<HTMLButtonElement>;
}

const PaymentPage = ({ title, orderInfo, buttonRef }: PaymentProps) => {
  const navigate = useNavigate();
  const [orderRequest, setOrderRequest] = useRecoilState(orderRequestState);
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);

  const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;

  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(
        clientKey!,
        orderInfo.orderNumber
      );

      paymentWidget.renderPaymentMethods(
        "#payment-widget",
        orderInfo.totalPrice
      );

      paymentWidgetRef.current = paymentWidget;
    })();
  }, [orderInfo.totalPrice]);

  async function handlePaymentButtonClick() {
    const paymentWidget = paymentWidgetRef.current;

    try {
      await paymentWidget?.requestPayment({
        orderId: orderInfo.orderNumber,
        orderName: title,
        customerName: orderInfo.userName,
        customerEmail: orderInfo.userEmail,
        successUrl: `${window.location.origin}/payment/success`,
        failUrl: `${window.location.origin}/payment/fail`,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Div position="relative" bottom="50px" right="25px">
      <div id="payment-widget" style={{ width: "100%" }} />
      <button
        style={{ display: "none" }}
        ref={buttonRef}
        onClick={handlePaymentButtonClick}
      />
    </Div>
  );
};

export default PaymentPage;

import styled from "styled-components";

export const FabricContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  margin-bottom: 5rem;

  //media query 스타일이 1073px미만일때 적용되게 됩니다.
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  //media query 스타일이 767px미만일때 적용되게 됩니다.
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
`;

import styled from "styled-components";

export const FabricImage = styled.img`
  width: 100%;

  aspect-ratio: 1/1;

  @media (max-width: 1023px) {
    aspect-ratio: 1/1;
  }
`;

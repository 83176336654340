export const productOptions = {
  ages: [
    { label: "20대", value: "20" },
    { label: "30대", value: "30" },
    { label: "40대", value: "40" },
    { label: "50대", value: "50" },
    { label: "60대 이상", value: "60" },
  ],
  heights: [
    { label: "160", value: 160 },
    { label: "163", value: 163 },
    { label: "166", value: 166 },
    { label: "169", value: 169 },
    { label: "172", value: 172 },
    { label: "175", value: 175 },
    { label: "178", value: 178 },
    { label: "181", value: 181 },
    { label: "183", value: 183 },
    { label: "186", value: 186 },
  ],
  chests: [
    { label: "85(XS)", value: "85" },
    { label: "90(S)", value: "90" },
    { label: "95(M)", value: "95" },
    { label: "100(L)", value: "100" },
    { label: "105(XL)", value: "105" },
    { label: "110(2XL)", value: "110" },
    { label: "115(3XL)", value: "115" },
    { label: "120(4XL)", value: "120" },
    { label: "125(5XL)", value: "125" },
  ],

  waists: [
    { label: "맞다", value: "맞다" },
    { label: "크다", value: "크다" },
    { label: "작다", value: "작다" },
  ],
  inchWaists: Array.from({ length: 21 }, (_, index) => {
    const size = 28 + index;
    return { label: `${size}inch`, value: size };
  }),
  seleeveHeights: [
    { label: "맞다", value: "맞다" },
    { label: "크다", value: "크다" },
    { label: "작다", value: "작다" },
  ],
  seleeveLengths: [
    { label: "맞다", value: "맞다" },
    { label: "길다", value: "길다" },
    { label: "짧다", value: "짧다" },
  ],
  thighs: [
    { label: "맞다", value: "맞다" },
    { label: "크다", value: "크다" },
    { label: "작다", value: "작다" },
  ],
  hips: [
    { label: "맞다", value: "맞다" },
    { label: "크다", value: "크다" },
    { label: "작다", value: "작다" },
  ],
  necks: [
    { label: "맞다", value: "맞다" },
    { label: "크다", value: "크다" },
    { label: "작다", value: "작다" },
  ],
  shirts: [
    { label: "긴소매", value: "긴소매" },
    { label: "반소매", value: "반소매" },
  ],
  pantLengthInch: Array.from({ length: 29 }, (_, index) => {
    const size = 86 + index;
    return { label: `${size}cm`, value: size };
  }),
  waistAdditionalInch: Array.from({ length: 29 }, (_, index) => {
    const size = 36 + index;
    return { label: `${size}cm`, value: size };
  }),
};

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getUserProductApi } from "~/apis/cart/get-userproduct-api";
import { orderApi } from "~/apis/order/order-api";
import Payment from "~/components/pages/payment/PaymentPage";
import {
  orderDetailState,
  orderPrdocutIdState,
  orderRequestState,
} from "~/store/order-state";
import Div from "../atoms/div";
import { OrderPaymentContainer } from "../atoms/order/OrderPaymentContainer";
import { PaymentButton } from "../atoms/payment/PaymentButton";
import { PaymentContainer } from "../atoms/payment/PaymentContainer";
import { PaymentItemContainer } from "../atoms/payment/PaymentItemContainer";
import { PaymentTitle } from "../atoms/payment/PaymentTitle";
import PaymentAddressInput from "../molecules/payment/PaymentAddressInput";
import PaymentInput from "../molecules/payment/PaymentInput";
import PaymentPrice from "../molecules/payment/PaymentPrice";
import PaymentProduct from "../molecules/payment/PaymentProduct";

const OrderPage = () => {
  const navigate = useNavigate();

  const [orderTitle, setOrderTitle] = useState("");
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [productIds, setProductIds] = useRecoilState(orderPrdocutIdState);
  const [order, setOrder] = useRecoilState(orderDetailState);
  const [orderRequest, setOrderRequest] = useRecoilState(orderRequestState);

  useEffect(() => {
    if (productIds.length === 0) {
      alert("잘못된 접근입니다.");
      return navigate("/cart");
    }

    getUserProductApi(productIds)
      .then((res) => {
        setOrder(res.data.data);
        setOrderTitle(
          `${res.data.data.products[0].title} 외 ${
            res.data.data.products.length - 1
          }건`
        );
        console.log(orderTitle);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    setOrderRequest({
      ...orderRequest,
      [key]: e.target.value,
    });
  }

  function handleOrderButtonClick() {
    if (orderRequest.totalPrice <= 0) {
      // alert("잘못된 결제입니다. 다시 시도해주세요.");
      // navigate("/cart");
    }
    orderApi({
      ...orderRequest,
      orderId: order.orderNumber,
      userProductIds: productIds.map((id) => parseInt(id, 10)),
      totalPrice: order.totalPrice,
    })
      .then((res) => {
        buttonRef.current?.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <PaymentContainer>
      <PaymentItemContainer isProduct>
        <PaymentTitle>상품</PaymentTitle>

        <OrderPaymentContainer>
          {order.products.map((product) => (
            <PaymentProduct
              title={product.title}
              options={product.options}
              quantity={product.quantity}
              additionalOptions={product.additionalOptions}
              fabricTitle={product.fabricName}
              fabricCode={product.fabricCode}
            />
          ))}
        </OrderPaymentContainer>
      </PaymentItemContainer>

      <PaymentItemContainer>
        <PaymentTitle>주문정보</PaymentTitle>
        <PaymentInput
          title="수령인"
          placeholder="수령인"
          value={orderRequest.recipient}
          onChange={(e) => handleChange(e, "recipient")}
        />
        <PaymentAddressInput />

        <PaymentInput
          title="전화번호"
          placeholder="전화번호"
          value={orderRequest.phone}
          onChange={(e) => handleChange(e, "phone")}
        />

        <PaymentInput
          title="가게요청사항"
          value={orderRequest.customerRequest}
          onChange={(e) => handleChange(e, "customerRequest")}
          placeholder="가게요청사항"
        />

        <PaymentInput
          title="배송요청사항"
          value={orderRequest.deliveryRequest}
          onChange={(e) => handleChange(e, "deliveryRequest")}
          placeholder="배송요청사항"
        />

        <Div height="1rem" />

        <PaymentPrice price={order.totalPrice} isPayment />

        <Payment
          title={orderTitle}
          orderRequest={{
            ...orderRequest,
            orderId: order.orderNumber,
            userProductIds: productIds.map((id) => parseInt(id, 10)),
            totalPrice: order.totalPrice,
          }}
          buttonRef={buttonRef}
          orderInfo={order}
        />
        <PaymentButton onClick={handleOrderButtonClick}>주문하기</PaymentButton>
      </PaymentItemContainer>
    </PaymentContainer>
  );
};

export default OrderPage;
